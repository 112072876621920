import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/zelezo-proc-je-v-dulezite.jpg'
import styles from './post-grid.module.css'

const ZelezoProcJeVDulezite = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Železo, proč je v našem jídelníčku důležité? | NutritionPro'}
        description={
          'Železo je minerální látka, která obvykle spadá pod mikroelementy, tedy stopové prvky. Je pro nás esenciální, což znamená, že je pro nás velice důležité, ale neumíme si ho v těle vytvořit sami, musíme ho proto přijímat potravou. Patří mezi nejrozšířenější minerální látky v přírodě a v těle se obvykle vyskytuje vázané na protein. Až 70 % železa v organismu je vázáno na hemová barviva, tedy hemoglobin, který v organismu zajišťuje přenos kyslíku, a myoglobin, který umožňuje skladování kyslíku ve svalech. Lidské tělo obsahuje 3 - 5 gramů železa. V našem těle hraje klíčovou roli a jeho nedostatek může způsobit celou řadu zdravotních problémů.'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/zelezo-proc-je-v-dulezite">
            Železo, proč je v našem jídelníčku důležité?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Železo, proč je v našem jídelníčku důležité?"
              date="09.04.2023"
            />
            <div>
              <h5 className={styles.postTitle}>K čemu nám je železo</h5>
              <p className={styles.postText}>
                Železo je minerální látka, která obvykle spadá pod
                mikroelementy, tedy <b>stopové prvky</b>. Je pro nás{' '}
                <b>esenciální</b>, což znamená, že je pro nás velice důležité,
                ale neumíme si ho v těle vytvořit sami, musíme ho proto přijímat
                potravou. Patří mezi nejrozšířenější minerální látky v přírodě a
                v těle se obvykle vyskytuje vázané na protein.{' '}
                <b>Až 70 % železa</b> v organismu je{' '}
                <b>vázáno na hemová barviva</b>, tedy hemoglobin, který v
                organismu zajišťuje přenos kyslíku, a myoglobin, který umožňuje
                skladování kyslíku ve svalech. Lidské tělo obsahuje 3 - 5 gramů
                železa. V našem těle hraje <b>klíčovou roli</b> a jeho
                nedostatek může způsobit celou řadu zdravotních problémů.
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="Jídlo"
              />

              <p className={styles.postText}>
                Železo je klíčovým prvkem pro tvorbu <b>hemoglobinu</b>, což je
                protein v červených krvinkách, který umožňuje{' '}
                <b>přenos kyslíku</b> z plic do ostatních částí těla. Také hraje
                důležitou roli v <b>imunitním systému</b>, kde podporuje funkci
                bílých krvinek a pomáhá bojovat proti infekcím. Stejně tak je
                důležité pro <b>metabolické funkce</b> v těle, jako je tvorba
                DNA a energie. Zlepšuje <b>kognitivní funkce</b>, jako je
                pozornost a paměť a je důležité pro děti a těhotné ženy, jelikož
                podporuje <b>růst a vývoj.</b>
              </p>

              <h5 className={styles.postTitle}>Denní potřeba železa</h5>
              <p className={styles.postText}>
                Denní potřeba{' '}
                <b>pro muže je okolo 10 mg. Pro ženy je to okolo 15 mg.</b>{' '}
                Speciální skupinou jsou adolescenti, pro které je u obou pohlaví
                potřeba ještě zhruba o 2 mg vyšší, a také{' '}
                <b>těhotné a kojící</b> ženy, jejichž denní potřeba je cca{' '}
                <b>30 mg</b> železa kvůli značným ztrátám.
              </p>

              <h5 className={styles.postTitle}>Železo v potravinách</h5>
              <p className={styles.postText}>
                <b>Využitelné formy</b> železa najdeme především{' '}
                <b>v živočišných zdrojích</b>, jako je <b>maso</b> (hlavně
                hovězí), <b>vnitřnosti a masné výrobky</b>. Zde je železo ve
                formě hemových barviv. Železo se nachází také ve <b>vejcích</b>,
                jeho využitelnost je zde ale výrazně menší.{' '}
                <b>Rostlinné potraviny</b>, jako jsou například špenát,
                luštěniny nebo obiloviny, obsahují relativně velké množství
                železa, je zde ovšem <b>problém s využitelností</b>, jelikož je
                železo vázáno v komplexu s dalšími látkami, které jeho využití
                brání.
              </p>

              <h5 className={styles.postTitle}>Nedostatek železa</h5>
              <p className={styles.postText}>
                Nedostatek železa je způsoben mnoha faktory, jako jsou například{' '}
                <b>zvýšené požadavky na železo</b> (děti, těhotné ženy),{' '}
                <b>zvýšená ztráta krve</b> (darování krve, krvácení) a{' '}
                <b>nedostatek zdrojů v potravě</b>. Při nedostatečném příjmu
                železa hrozí <b>anémie</b>, tedy <b>chudokrevnost</b>, jelikož
                dochází k nedostatečné tvorbě hemu (barvivo). Mezi příznaky
                patří únava, malátnost, celková slabost, poruchy spánku a
                koncentrace, bolesti hlavy a zvýšená citlivost na chlad.
              </p>

              <h5 className={styles.postTitle}>Nadbytek železa </h5>
              <p className={styles.postText}>
                Nadbytek železa v těle je důsledkem{' '}
                <b>poruchy metabolismu železa</b>, což může být důsledkem
                několika poruch v těle. Příznaky nadbytku jsou únava, slabost,
                bolesti břicha, hubnutí, jaterní léze, záněty kloubů nebo
                porucha funkce pohlavních žláz. Typická je také{' '}
                <b>hemochromatóza</b>, neboli bronzový diabetes, který se
                projevuje pigmentací kůže.
              </p>

              <h5 className={styles.postTitle}>Závěrem</h5>
              <p className={styles.postText}>
                Celkově lze říci, že železo je důležitým minerálem pro mnoho
                klíčových funkcí v našem těle, zejména pro{' '}
                <b>tvorbu hemoglobinu a metabolismus</b>, a nedostatek železa
                může mít negativní dopad na naše zdraví. Proto je důležité
                zajistit dostatečný příjem železa z potravy a léčit jeho
                nedostatek v případě potřeby. Také je nutné zohlednit vhodnost
                potravinových zdrojů.
              </p>

              <p className={styles.postText}>
                <b>TIP:</b>{' '}
                <i>
                  Chcete si být jistí, že máte dostatek železa? Vsaďte na{' '}
                  <b>
                    <a href="https://nutritionpro.cz/" target="_blank">
                      NutritionPro krabičky.
                    </a>
                  </b>
                </i>
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default ZelezoProcJeVDulezite
